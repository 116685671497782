<template>
<link href="https://cdn.jsdelivr.net/npm/animate.css@3.5.1" rel="stylesheet" type="text/css">
  <div class="catalogue">
    <div class="catalogue-header">
      <div class="wrapper-header-left">
        <transition name="slide-fade" >
          <h1 v-if="titleShow">GIGIHPACK</h1>
        </transition>
        <transition name="slide-fade" >
          <h3 v-if="taglineShow" >
            <span v-if="$store.state.lang == 'IN'">Percayakan pengemasan terbaik hanya pada Gigihpack.</span>
            <span v-if="$store.state.lang == 'EN'">Believe the best packaging only to gigihpack.</span>
          </h3>
        </transition>
      </div>
    </div>
    <div class="wrepper-content-catalogue">
      <div class="catalogue-top">
        <h2>
          <span v-if="$store.state.lang == 'IN'">Kemasan Produk Berpengaruh Bagi Usaha Anda Loh!</span>
          <span v-if="$store.state.lang == 'EN'">Packaging does matter to your business!</span>
        </h2>
        <p v-if="$store.state.lang == 'IN'">Hallo sobat Gigih,  kamu sedang menjalani bisnis online atau offline? Nah Gigihpack mau kasih info nih. Kemasan atau packaging produk sangat berpengaruh bagi usahamu. Kenapa? Yuk simak</p>
        <p v-if="$store.state.lang == 'EN'">Hello buddy Gigih, are you going online or offline? Well, gigihpack wanted to update me. Packaging or packaging products have a big impact on your operation. - why? Let's listen</p>
      </div>
      <Waypoint @change="showItemA"></Waypoint>
      <transition enter-active-class="animated flipInX" >
        <div class="catalogue-left" v-show="sItemA" >
          <img src="../assets/image/IMAGE6.jpg" />
        </div>
      </transition>
      <transition name="slide-fade" >
        <div v-show="mapShow" class="catalogue-right" >
          <it-collapse>
            <it-collapse-item icon="check" title="Melindungi Produk" opened="true" v-if="$store.state.lang == 'IN'">
              Sobat, dengan adanya kemasan maka otomatis produk makanan akan terlindungi dari kuman atau bakteri. Perlu diketahui bahwa kerusakan produk sangat dihindari oleh konsumen karena hal itu akan merugikan mereka.
            </it-collapse-item>
            <it-collapse-item icon="check" title="Protecting the product" opened="true" v-if="$store.state.lang == 'EN'">
              Buddy, with the packaging, the food products are automatically protected from germs or bacteria. It should be noted that damage to the product is largely avoided by the consumer as it would cost them their lives.
            </it-collapse-item>
          </it-collapse>
          <it-collapse>
            <it-collapse-item title="Membangun Kepercayaan" opened="true" v-if="$store.state.lang == 'IN'">
              Pada setiap kemasan produk biasanya akan ada informasi yang sesuai produk itu sendiri dan otomatis kepercayaan konsumen akan terbangun.
            </it-collapse-item>
            <it-collapse-item title="Building Trust" opened="true" v-if="$store.state.lang == 'EN'">
              In each packaging of the product there will usually be information consistent with the product itself and automatically consumer trust will be awakened.
            </it-collapse-item>
          </it-collapse>
          <it-collapse>
            <it-collapse-item title="Menambah Daya Tarik" opened="true" v-if="$store.state.lang == 'IN'">
              Kemasan harus dibuat unik agar berbeda dari produk lainnya misalnya dari segi design sebaiknya tidak dilakukan dengan asal-asalan. Kemasan yang unik akan membuat daya tarik bagi konsumen.
            </it-collapse-item>
            <it-collapse-item title="Adding Attraction" opened="true" v-if="$store.state.lang == 'EN'">
              The packaging has to be unique in order to distinguish from other products such as design design should not be done perhaphazardly. The unique packaging makes the consumer attractive.
            </it-collapse-item>
          </it-collapse>
          <it-collapse>
            <it-collapse-item title="Mempromosikan Produk" opened="true" v-if="$store.state.lang == 'IN'">
              Kemasan juga bisa sebagai wadah untuk berpromosi tentang produkmu. Jangan lupa cantumkan nama brand, tagline, cara pembuatan,dsb.
            </it-collapse-item>
            <it-collapse-item title="Promoting Products" opened="true" v-if="$store.state.lang == 'EN'">
              The packaging can also serve asa platform for promoting your product. Don't forget to put the brand name, the tagline, the manufacturing, etc.
            </it-collapse-item>
          </it-collapse>
          <it-collapse>
            <it-collapse-item title="Membuat Perbedaan" opened="true" v-if="$store.state.lang == 'IN'">
              Jika kamu memasarkan produk yang sama dengan pebisnis lain, kemasan produk dapat menjadi strategi untuk membuat perbedaan. Kemasan akan menjadi identitas kamu.
By Risa / December 29,2021.
            </it-collapse-item>
            <it-collapse-item title="Make a Difference" opened="true" v-if="$store.state.lang == 'EN'">
              If you market the same product as other entrepreneurs, packaging can be a strategy to make a difference. The pack will be your identity. By risa/December 29.2021.
            </it-collapse-item>
          </it-collapse>
        </div>
      </transition>
      <transition name="slide-fade" >
        <div class="catalogue-left" v-show="sItemA" >
          <h2><span v-if="$store.state.lang == 'IN'" >Kemasan Kami</span><span v-if="$store.state.lang == 'EN'" >Our Pack</span></h2>
          <p v-if="$store.state.lang == 'IN'" ><b>Gigihpack</b> terus berkomitmen atas kepercayaan yang telah diberikan mitra kami untuk pengerjaan kemasan yang baik dengan kualitas hasil cetak yang jernih,akurat dan tajam.</p>
          <p v-if="$store.state.lang == 'EN'" ><b>Gigihpack</b> continues to commit to the trust our partners have given us to work on a good packaging with a clear, accurate and sharp quality of printing.</p>
          <h3 v-if="$store.state.lang == 'IN'" >Bahan : </h3>
          <h3 v-if="$store.state.lang == 'EN'" >Material : </h3>
          <ul>
            <li> <it-icon name="check_circle" color="#644627" /> Art cartoon 190 - 400 gr </li>
            <li> <it-icon name="check_circle" color="#644627" /> Art paper 100- 150 gr </li>
            <li> <it-icon name="check_circle" color="#644627" /> Ivory 210-390 gr </li>
            <li> <it-icon name="check_circle" color="#644627" /> Greas proof 40 - 50 gr </li>
            <li> <it-icon name="check_circle" color="#644627" /> White kraft 50 - 150 gr </li>
            <li> <it-icon name="check_circle" color="#644627" /> Brown kraft 50 - 150 gr </li>
          </ul>
        </div>
      </transition>
      <transition enter-active-class="animated flipInX" >
        <div v-show="mapShow" class="catalogue-right catalogue-right2" >
          <vue-picture-swipe :items="[
            {src: getImage('IMAGE7.jpeg'), thumbnail: getImage('IMAGE7.jpeg'),w: 600,h: 900},
            {src: getImage('IMAGE07.jpg'), thumbnail: getImage('IMAGE07.jpg'),w: 600,h: 600},
            {src: getImage('IMAGE007.jpeg'), thumbnail: getImage('IMAGE007.jpeg'),w: 600,h: 600},
            {src: getImage('IMAGE0007.jpg'), thumbnail: getImage('IMAGE0007.jpg'),w: 600,h: 600},
            {src: getImage('IMAGE00007.jpeg'), thumbnail: getImage('IMAGE00007.jpeg'),w: 600,h: 600},
            {src: getImage('IMAGE000007.jpeg'), thumbnail: getImage('IMAGE000007.jpeg'),w: 600,h: 600}]">
          </vue-picture-swipe>
        </div>
      </transition>
      <div class="catalogue-top">
        <h2><span v-if="$store.state.lang == 'IN'" >Lunch Box (Box Makanan)</span><span v-if="$store.state.lang == 'EN'" >Lunch Box</span></h2>
      </div>
      <div class="catalogue-full catalogue-full1">
        <h3 v-if="$store.state.lang == 'IN'" >Lunch Box berbahan dasar foodgrade sehingga aman jika bersentuhan dengan makanan, mudah di daur ulang. <br />Umumnya ukuran : </h3>
        <h3 v-if="$store.state.lang == 'EN'" >Lunch boxes are foodgrade so that it's safe to come in contact with food, easy to recycle. <br />Generally size : </h3>
        <ul>
          <li> <it-icon name="check_circle" color="#FFF" /> small (10,5 x 10,5 x 5 cm) </li>
          <li> <it-icon name="check_circle" color="#FFF" /> medium (16 x 9 x 5 cm) </li>
          <li> <it-icon name="check_circle" color="#FFF" /> large (18 x 11 x 5 cm)</li>
          <li v-if="$store.state.lang == 'IN'"> <it-icon name="check_circle" color="#FFF" /> ukuran & bahan bisa custom </li>
          <li v-if="$store.state.lang == 'EN'"> <it-icon name="check_circle" color="#FFF" /> Size & ingredients can be custom </li>
          <li v-if="$store.state.lang == 'IN'"> <it-icon name="check_circle" color="#FFF" /> Lunch Box bisa digunakan untuk Dine in </li>
          <li v-if="$store.state.lang == 'EN'"> <it-icon name="check_circle" color="#FFF" /> Lunch box is just for lunch  </li>
        </ul>
      </div>
      <div class="catalogue-top">
        <h2><span>Lunch Tray</span></h2>
      </div>
      <div class="catalogue-full catalogue-full2">
        <h3 v-if="$store.state.lang == 'IN'">Lunch Tray berbahan dasar foodgrade sehingga aman jika bersentuhan dengan makanan, mudah di daur ulang. <br />Umumnya berukuran : </h3>
        <h3 v-if="$store.state.lang == 'EN'">Lunch tray is a foodgrade dish so that it's safe to come into contact with food, easy to recycle. <br />Average size : </h3>
        <ul>
          <li> <it-icon name="check_circle" color="#FFF" /> small (10,5 x 10,5 x 5 cm) </li>
          <li> <it-icon name="check_circle" color="#FFF" /> medium (16 x 9 x 5 cm) </li>
          <li> <it-icon name="check_circle" color="#FFF" /> large (18 x 11 x 5 cm)</li>
          <li v-if="$store.state.lang == 'IN'"> <it-icon name="check_circle" color="#FFF" /> ukuran & bahan bisa custom </li>
          <li v-if="$store.state.lang == 'EN'"> <it-icon name="check_circle" color="#FFF" /> Size & ingredients can be custom </li>
          <li v-if="$store.state.lang == 'IN'"> <it-icon name="check_circle" color="#FFF" /> Lunch Tray bisa digunakan untuk Dine in  </li>
          <li v-if="$store.state.lang == 'EN'"> <it-icon name="check_circle" color="#FFF" /> Lunch tray's gonna be used for this  </li>
        </ul>
      </div>
      <div class="catalogue-top">
        <h2>
          <span v-if="$store.state.lang == 'IN'">Shopping Bag (Tas Kertas)</span>
          <span v-if="$store.state.lang == 'EN'">Shopping Bag</span>
        </h2>
      </div>
      <div class="catalogue-full catalogue-full3">
        <h3 v-if="$store.state.lang == 'IN'">Shopping Bag sangatlah ekslusif jika sebagai tas pengganti plastik, mudah di daur ulang. Ukuran & bahan bisa custom.</h3>
        <h3 v-if="$store.state.lang == 'EN'">Shopping bags are very exclusive when a plastic substitute bag is easy to recycle. Size & ingredients can be custom.</h3>
      </div>
      <div class="catalogue-top">
        <h2>
          <span v-if="$store.state.lang == 'IN'">Paper Bag (Kantung Kertas)</span>
          <span v-if="$store.state.lang == 'EN'">Paper Bag</span>
        </h2>
      </div>
      <div class="catalogue-full catalogue-full4">
        <h3 v-if="$store.state.lang == 'IN'">Paper Bag berbahan dasar foodgrade sehingga aman jika bersentuhan dengan makanan. sangatlah efektif jika digunakan sebagai kantung makanan seperti Fried Chicken,Roti,makanan berminyak & kering lainnya. mudah di daur ulang. Ukuran & bahan bisa custom.</h3>
        <h3 v-if="$store.state.lang == 'EN'">A bottom paper bag is foodgrade so it's safe to come in contact with food. It's very effective when used as food bags such as Fried chicken, bread, greasy & other foods. Easy to recycle. Size & ingredients can be custom.</h3>
      </div>
      <div class="catalogue-top">
        <h2><span>Food Pail</span></h2>
      </div>
      <div class="catalogue-full catalogue-full5">
        <h3 v-if="$store.state.lang == 'IN'">Food Pail berbahan dasar foodgrade sehingga aman jika bersentuhan dengan makanan. sangatlah mewah jika digunakan sebagai wadah makanan, mudah di daur ulang. Ukuran & bahan bisa custom. Food Pail bisa digunakan untuk take away</h3>
        <h3 v-if="$store.state.lang == 'EN'">Food pail had a foodgrade basis so that it was safe to come in contact with food. It is very fancy if used as a food container, easy to recycle. Size & ingredients can be custom. Food pail can be used for take away.</h3>
      </div>
      <div class="catalogue-top">
        <h2><span>Wrapper</span></h2>
      </div>
      <div class="catalogue-full catalogue-full6">
        <h3 v-if="$store.state.lang == 'IN'">Wrapper berbahan dasar foodgrade sehingga aman jika bersentuhan dengan makanan. sangatlah mewah jika digunakan sebagai wadah makanan, mudah di daur ulang. Ukuran & bahan bisa custom.</h3>
        <h3 v-if="$store.state.lang == 'EN'">Wrapper made from foodgrade so it is safe when in contact with food. very luxurious if used as a food container, easy to recycle. Size & material can be customized.</h3>
      </div>
      <div class="catalogue-top">
        <h2><span>Paper Bowl</span></h2>
      </div>
      <div class="catalogue-full catalogue-full7">
        <h3 v-if="$store.state.lang == 'IN'">Wrapper  berbahan dasar foodgrade sehingga aman jika bersentuhan dengan makanan. sangatlah mewah jika digunakan sebagai wadah makanan, mudah di daur ulang.<br />Umumnya ukuran : </h3>
        <h3 v-if="$store.state.lang == 'EN'">Wrapper based on foodgrade so it's safe if it comes in contact with food. It is very fancy if used as a food container, easy to recycle.<br />Generally size : </h3>
        <ul>
          <li> <it-icon name="check_circle" color="#FFF" /> 1 oz = 30 ml </li>
          <li v-if="$store.state.lang == 'IN'"> <it-icon name="check_circle" color="#FFF" /> Ukuran 22 oz </li>
          <li v-if="$store.state.lang == 'EN'"> <it-icon name="check_circle" color="#FFF" /> Size 22 oz </li>
          <li v-if="$store.state.lang == 'IN'"> <it-icon name="check_circle" color="#FFF" /> Ukuran 24 oz </li>
          <li v-if="$store.state.lang == 'EN'"> <it-icon name="check_circle" color="#FFF" /> Size 24 oz </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import { Waypoint } from 'vue-waypoint'
import VuePictureSwipe from 'vue-picture-swipe'

export default {
  name: 'Catalogue',
  components: {
    Waypoint,
    VuePictureSwipe
  },
  data () {
    return {
      sItemA: false,
      titleShow: false,
      taglineShow: false,
      mapShow: false
    }
  },
  mounted () {
    setTimeout(() => {
      this.titleShow = true
    }, 100)
    setTimeout(() => {
      this.taglineShow = true
    }, 300)
    setTimeout(() => {
      this.imgShow = true
    }, 500)
  },
  methods: {
    getImage (imgName) {
      var image = require.context('../assets/image/', false)
      return image('./' + imgName)
    },
    showItemA (waypointState) {
      if (waypointState.going === 'IN' && waypointState.direction !== undefined) {
        setTimeout(() => {
          this.sItemA = true
          this.mapShow = true
        }, 300)
      }
    }
  }
}
</script>
<style lang="scss">
  .catalogue{
    position: relative;
    min-height: 90vh;
    padding: 2.5rem;
    text-align: left;
    background: #fff;
    border-radius: 1rem 1rem 0rem 0rem;
    font-size: 1.2rem;
    .catalogue-header{
      background-image: url("../assets/image/IMAGE5.jpg");
      box-shadow: inset 0 0 0 2000px rgb(0 0 0 / 37%);
      position: relative;
      background-color: #fafafa;
      overflow: hidden;
      border-radius: 0.8rem;
      margin: -2.5rem;
      background-attachment: fixed;
      background-position-x: 23rem;
      background-position-y: 13vh;
      background-repeat: no-repeat;
      background-size: 71%;
      transition: 700ms;
      min-height: 90vh;
      .wrapper-header-left{
        display: inline-grid;
        width: 100%;
      }
      h1{
        font-size: 3rem;
        font-family: LondonTwo;
        color: #fff;
        text-shadow: -1px -1px 0px #644627;
        text-align: left;
        float: left;
        margin-left: 12%;
        margin-top: 35vh;
        margin-bottom: 0rem;
        span {
          background: #644627;
          border-radius: .6rem;
          display: inline-flex;
          height: 1.5rem;
          line-height: 0px;
        }
      }
      h3{
        float: left;
        color: #fff;
        text-align: left;
        width: 92%;
        margin-left: 12%;
        text-shadow: 0px 1px 2px #644627;
      }
    }
    h2, h3, h4{
      color: #644627;
      text-shadow: 0px 1px 2px #fff;
      margin-bottom: .3rem;
      span{
        float: left;
      }
    }
    p {
      line-height: 1.3rem;
    }
    .it-btn{
      float: left;
      margin-right: 0.5rem;
      margin-top: 0.5rem;
    }
    .iconify{
        vertical-align: middle;
    }
    .wrepper-content-catalogue{
      position: relative;
      width: 100%;
      margin-top: 6rem;
      overflow: hidden;
      .catalogue-top{
        display: inline-block;
        width: 100%;
        h2{
          width: 80%;
          font-size: 2rem;
          font-family: LondonTwo;
          color: #644627;
          text-shadow: -1px -1px 0px #ededed;
          text-align: left;
          float: left;
          padding-left: 10rem;
          padding-right: 10rem;
          padding-top: 3rem;
          margin-bottom: 1rem;
          span {
            background: #ededed;
            border-radius: .6rem;
            display: inline-flex;
            height: 1rem;
            line-height: 0px;
          }
        }
        p{
          width: 80%;
          padding-left: 10rem;
          padding-right: 10rem;
          padding-bottom: 1.5rem;
        }
      }
      .catalogue-left{
        width: 36%;
        padding-left: 10rem;
        float: left;
        display: inline-block;
        img{
          border-radius: 3px;
          width: 100%;
        }
        ul{
          list-style-type: none;
          padding: 0px;
          li{
            line-height: 2rem;
            .it-icon{
              padding-right: 0.5rem;
              vertical-align: middle;
            }
          }
        }
        h2{
          width: 100%;
          font-size: 2rem;
          font-family: LondonTwo;
          color: #644627;
          text-shadow: -1px -1px 0px #ededed;
          text-align: left;
          float: left;
          padding-top: 3rem;
          margin-bottom: 1rem;
          span {
            background: #ededed;
            border-radius: .6rem;
            display: inline-flex;
            height: 1rem;
            line-height: 0px;
          }
        }
      }
      .catalogue-left:nth-of-type(2n){
        width: 30%;
      }
      .catalogue-right{
        display: inline-block;
        width: 37%;
        border-radius: 1rem;
        text-align: center;
        vertical-align: middle;
        .it-collapse{
          width: 95%;
          margin-left: auto;
          margin-right: 0px;
          margin-bottom: 1rem;
          text-align: left;
          .it-collapse-item-title{
            background-color:#644627;
            color: #fafbfd;
            font-weight: bold;
          }
          .it-collapse-item-body{
            background-color: rgb(100 70 39 / 11%);
          }
        }
        .my-gallery{
          img{
            width: 200px;
            height: 200px;
            object-fit: cover;
            border-radius: 3px;
            transition: all 500ms;
            opacity: .9;
          }
          img:hover{
            height: 210px;
            opacity: 1;
          }
        }
      }
      .catalogue-right2{
        width: 50%;
        padding-top:1.5rem;
      }
      .catalogue-full1{
        background-image: url("../assets/image/IMAGE8.jpg");
      }
      .catalogue-full2{
        background-image: url("../assets/image/IMAGE9.jpg");
      }
      .catalogue-full3{
        background-image: url("../assets/image/IMAGE10.jpg");
      }
      .catalogue-full4{
        background-image: url("../assets/image/IMAGE11.jpg");
      }
      .catalogue-full5{
        background-image: url("../assets/image/IMAGE12.jpg");
      }
      .catalogue-full6{
        background-image: url("../assets/image/IMAGE13.jpg");
      }
      .catalogue-full7{
        background-image: url("../assets/image/IMAGE14.jpg");
      }
      .catalogue-full{
        margin-right: 10rem;
        margin-left: 10rem;
        background-size: cover;
        background-repeat: no-repeat;
        border-radius: 3px;
        padding: 10rem;
        min-height: 26rem;
        color: #fff;
        box-shadow: inset 0 0 0 0px rgb(0 0 0 / 35%);
        transition: 1s;
        cursor:default;
        h3{
          color: #FFF;
          text-shadow: 0px 1px 2px #644627;
          opacity: 0;
          transition: 1s;
        }
        ul{
          list-style-type: none;
          padding: 0px;
          opacity: 0;
          transition: 1s;
          li{
            line-height: 2rem;
            .it-icon{
              padding-right: 0.5rem;
              vertical-align: middle;
            }
          }
        }
      }
      .catalogue-full:hover{
        box-shadow: inset 0 2000px 0 0px rgb(0 0 0 / 35%);
        h3{
          opacity: 1;
        }
        ul{
          opacity: 1;
        }
      }
    }
  }
  @media only screen and (max-width: 650px) {
    .catalogue{
      .catalogue-header{
        background-position-x: 0rem;
        background-position-y: 9vh;
        background-size: cover;
        min-height: 90vh;
        h2{
          font-size: 2rem;
          margin-left: 15%;
        }
        h3{
          font-size: 1rem;
          width: 85%;
          margin-left: 15%;
          text-align: left;
        }
      }
      .wrepper-content-catalogue{
        margin: 0rem;
        margin-top: 4rem;
        .catalogue-left{
          width: 100% !important;
          padding-left: 0rem;
          padding-right: 0rem;
          img{
            display: none;
          }
        }
        .catalogue-right{
          margin-right: 0rem;
          width: 100%;
          margin-top: 0rem;
          background-image: url(/img/IMAGE6.23e928d7.jpg);
          box-shadow: inset 0 0 0 2000px rgb(0 0 0 / 71%);
          background-size: cover;
          background-repeat: no-repeat;
          .it-collapse{
            width: 100%;
            .it-collapse-item-body{
              color: #fafbfd;
            }
          }
          .my-gallery{
            img{
              width: 95%;
            }
          }
        }
        .catalogue-right2{
          background-image: none;
          box-shadow: none;
        }
        .catalogue-full{
          padding: 1rem;
          margin-right: 0rem;
          margin-left: 0rem;
          margin-top: -1rem;
          box-shadow: inset 0 2000px 0 0px rgb(0 0 0 / 35%);
          h3{
            opacity: 1;
            font-size: 1rem;
          }
          ul{
            opacity: 1;
            font-size: 1rem;
          }
        }
        .catalogue-top{
          h2{
            width: 100%;
            font-size: 1rem;
            padding-left: 0rem;
            span{
              background: none;
              line-height: 1.3rem;
              margin-bottom: 1.5rem;
            }
          }
          P{
            padding-left: 0rem;
            padding-right: 0rem;
            padding-bottom: 0.5rem;
          }
        }
      }
    }
  }
</style>
